<template>
  <table class="ui single line table history">
    <thead>
      <tr>
        <th>Data</th>
        <th>Responsável</th>
        <th>Alterou para</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, key) in tableData" :key="key">
        <td>{{ data.date }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.changed }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'THistoryTable',
  data () {
    return {
      tableData: [
        { date: '20/02/2020', name: 'Nome Usuário', changed: 'Nome Organização' },
        { date: '20/02/2020', name: 'Nome Usuário', changed: 'Nome Organização' },
        { date: '20/02/2020', name: 'Nome Usuário', changed: 'Nome Organização' },
        { date: '20/02/2020', name: 'Nome Usuário', changed: 'Nome Organização' },
        { date: '20/02/2020', name: 'Nome Usuário', changed: 'Nome Organização' }
      ]
    }
  }
}
</script>
