var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "ui single line table history" }, [
    _vm._m(0),
    _c(
      "tbody",
      _vm._l(_vm.tableData, function(data, key) {
        return _c("tr", { key: key }, [
          _c("td", [_vm._v(_vm._s(data.date))]),
          _c("td", [_vm._v(_vm._s(data.name))]),
          _c("td", [_vm._v(_vm._s(data.changed))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Data")]),
        _c("th", [_vm._v("Responsável")]),
        _c("th", [_vm._v("Alterou para")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }